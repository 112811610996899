<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>DATA IKLAN </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputIklan">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="title"
                label="Nama Iklan"
                placeholder="Input Nama Iklan"
              />
              </CCol>

              <CCol sm="6">
              <div class='form-group'>
                <label>Ukuran</label>
                <select v-model="advert_size" id="" class="form-control bold input-lg" required="">
                    <option class="bold" value="">Pilih Ukuran</option>
                    <option class="bold" value="2">300X300</option>
                    <option class="bold" value="3">728X90</option>
                    <option class="bold" value="4">970X90</option>
                    <option class="bold" value="5">970X450</option>
                </select>
              </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>File Foto (jpeg/png/gif)</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon">
                    <label class="custom-file-label" for="inputGroupFile">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <CInput
                type="text"
                v-model="link"
                label="Link"
                placeholder="Input Link Iklan"
              />
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label> Status</label>
                <select v-model="status" placeholder='Pilih Status' class="form-control">
                  <option value="" disabled selected>Pilih Status</option>
                  <option value="1">Aktif</option>
                  <option value="0">Non-Aktif</option>
                </select>
                </div>
              </CCol>      
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/iklan">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Iklan gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
// import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      title : "",
      advert_size : "",
      val1 : "",
      val1_text : "",
      placeholder : "",
      link : "",
      status : "",
      // fproduct_price : "",
      // qty : "",
      // barcode : "",
      // alert : "",
      // product_des : "",
      // unit : "",
      selected: [], // Must be an array reference!
      show: true,
    }
  },
  methods: {

    onFileChange(event) {
      var fileData = event.target.files[0];
      this.val1_text = fileData.name;
      this.val1 = fileData;
      this.placeholder = fileData.name;
    },
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputIklan: function(){
      // Simple POST request with a JSON body using axios
      const formData = new FormData();        
        
        formData.append('title', this.title)
        formData.append('advert_size', this.advert_size)
        formData.append('link', this.link)
        formData.append('status', this.status)
        if (this.val1_text) {
          if(this.val1.name){
            formData.append('val1', this.val1, this.val1.name) 
            formData.append('val1_text', this.val1.name) 
          }
        }
      // alert(JSON.stringify(iklan));
      axios.post(process.env.VUE_APP_BASE_URL+"iklan/insert", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/iklan');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>
